import Vue from "vue";
import App from "./App.vue";
import "./assets/scss/main.scss";
import vuetify from "./plugins/vuetify";
import VueMeta from 'vue-meta';
Vue.use(VueMeta)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount("#app");
