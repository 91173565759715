<template>
  <v-app>
    <v-main class="container--fluid">
      <v-container fluid ma-0 pa-0 id="appContainer">
        <Home />
        <Works />
        <About-Me />
        <Skills />
        <Resume />
        <Testimonials />
        <Interests />
        <Contact />
        <Footer />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Pages/Home";
// const Home = () => import(/* webpackPrefetch: true */ "@/components/Pages/Home");
const Works = () => import(/* webpackPrefetch: true */ "@/components/Pages/Works");
const AboutMe = () => import(/* webpackPrefetch: true */ "@/components/Pages/AboutMe");
const Skills = () => import(/* webpackPrefetch: true */ "@/components/Pages/Skills");
const Resume = () => import(/* webpackPrefetch: true */ "@/components/Pages/Resume");
const Testimonials = () => import(/* webpackPrefetch: true */ "@/components/Pages/Testimonials");
const Interests = () => import(/* webpackPrefetch: true */ "@/components/Pages/Interests");
const Contact = () => import(/* webpackPrefetch: true */ "@/components/Pages/Contact");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Pages/Footer");

export default {
  name: "App",
    metaInfo: {
      title: 'Naveen DB',
      titleTemplate: '%s | Front-end Engineer'
    },
  components: {
    Home,
    Works,
    AboutMe,
    Skills,
    Resume,
    Testimonials,
    Interests,
    Contact,
    Footer
  },
  mounted() {
    // const activeTheme = this.getMediaPreference(); //based on system theme
    const activeTheme = this.getUserPreference(); //based on toggle switch
    this.setTheme(activeTheme);
  },
  methods: {
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },

    getUserPreference() {
      const theme = localStorage.getItem("user-theme");
      if(theme === null || theme === undefined || theme == "undefined") {
        return "light-theme";
        // return theme;
      } else {
        return theme;
      }
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      document.documentElement.className = theme;
    }
  }
};
</script>
